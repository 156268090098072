<template>
    <DatePicker 
            v-model="date" 
            :enableTimePicker="false" 
            range 
            multi-calendars
            :dark="$store.state.darkMode"
            :timezone="tz"
            auto-apply
            :start-time="{
                hours:0,
                minutes:0,
                seconds:0
            }"
            :utc="true"
            :min-date="minDate"
            @update:model-value="handleDateUpdate"
            style="font-size: 0.7rem;"
              />
</template>

<script>
//import { ref } from 'vue';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
    name: 'DatePickerComponent',
    emits: ['date-selected'],
    props: ["dates", "minDate"],
    components: {
        DatePicker
    },
    data() {
        return {
            date: this.dates,
            tz: 'UTC'
        }  
    },
    methods: {
        handleDateUpdate() {
            this.$emit('date-selected',this.date);
        }
    },
    mounted() {
        if (!this.dates || !this.dates[0] || this.dates[0] == 'Invalid Date') {
            const currentDate = new Date();
            currentDate.setUTCHours(0,0,0,0);
            currentDate.setTime(currentDate.getTime()-(currentDate.getTimezoneOffset()*60000))
            const startDate = new Date(currentDate.getTime());
            startDate.setDate(startDate.getDate() - 7)
            const endDate = currentDate;
            endDate.setUTCHours(23,59,59,0);
            endDate.setTime(endDate.getTime()+(endDate.getTimezoneOffset()*60000))
            this.date = [startDate, endDate];
            this.$emit('date-selected',this.date);

        }

    }
};
</script>
<style>
.dp__input {
    font-size:0.7rem;
}
</style>